<template>
  <!-- <multipane class="vertical-panes" layout="vertical">
    <div class="pane searchPane" :style="{ minWidth: '20%', width: '20%', maxWidth: '70%' }">
      <search-note></search-note>
    </div>
    <multipane-resizer></multipane-resizer>
    <div class="pane" :style="{ flexGrow: 1 }">
      <notes-tabs></notes-tabs>
    </div>
  </multipane>-->
  <notes-tabs class="notes-tabs"></notes-tabs>
</template>

<script>
import { Multipane, MultipaneResizer } from "vue-multipane";
import NotesTabs from "./NotesTabs.vue";

export default {
  name: "NotesContainer",
  components: { Multipane, MultipaneResizer, NotesTabs }
};
</script>

<style scoped>
/* .vertical-panes {
  width: 100%;
  border: 1px solid #ccc;
  height: 100%;
}
.vertical-panes > .pane {
  text-align: left;
  padding: 15px;
  background: #eee;
  height: 100%;
}
.vertical-panes > .pane ~ .pane {
  border-left: 1px solid #ccc;
} */
.notes-tabs {
  height: 100%;
  overflow-y: hidden;
}
</style>
