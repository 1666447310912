import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user/module";
import notes from "./modules/notes/module";
import notetopics from "./modules/notetopics/module";
import notetopicManager from "./modules/notetopicManager/module";
import searchnotes from "./modules/searchnotes/module";
import error from "./modules/error/module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notes,
    notetopics,
    searchnotes,
    notetopicManager,
    error
  }
});
