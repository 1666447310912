<template>
  <div class="p-3">
    <b-form class>
      <b-form-group label="E-Mail:" label-for="emailInput">
        <b-form-input
          id="emailInput"
          type="email"
          v-model="email"
          required
          placeholder="Enter E-Mail"
          :state="isEmailValid"
        ></b-form-input>
        <b-form-invalid-feedback>Enter a valid e-mail address</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Username:" label-for="usernameInput">
        <b-form-input
          id="usernameInput"
          type="text"
          v-model="user"
          required
          placeholder="Enter username"
          :state="isUserNameValid"
        ></b-form-input>
        <b-form-invalid-feedback>{{isRegisterUserNameAssigned ? 'The user name is already assigned' : 'Enter a valid user name'}}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Password:" label-for="passwordInput">
        <b-form-input
          id="passwordInput"
          type="password"
          v-model="password"
          placeholder="Enter password"
          required
          :state="isPasswordValid"
        ></b-form-input>
        <b-form-invalid-feedback>Enter a password with at least 8 characters</b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <b-button :disabled="!readyForRegister" @click="onRegisterBtnPressed()">Register</b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      user: "",
      password: ""
    };
  },
  computed: {
    isEmailValid() {
      return !!this.email && !!this.email.match(/@/);
    },
    isUserNameValid() {
      return !!this.user && !this.isRegisterUserNameAssigned;
    },
    isPasswordValid() {
      return !!this.password && this.password.length >= 8;
    },
    readyForRegister() {
      return this.isEmailValid && this.isUserNameValid && this.isPasswordValid;
    },
    isRegisterUserNameAssigned() {
      return this.isUserNameAssigned();
    }
  },
  methods: {
    ...mapActions("user", ["register", "isUserAssigned"]),
    ...mapGetters("user", ["isUserNameAssigned"]),
    onRegisterBtnPressed() {
      this.register({
        email: this.email,
        username: this.user,
        password: this.password
      });
    }
  },
  watch: {
    user(oNewUser, oOldUser) {
      if (oNewUser !== oOldUser) {
        this.isUserAssigned(oNewUser);
      }
    }
  }
};
</script>
