import noteTopicState from "./noteTopicManagerState";
import noteTopicGetters from "./noteTopicManagerGetters";
import noteTopicMutations from "./noteTopicManagerMutations";
import noteTopicActions from "./noteTopicManagerActions";

export default {
  namespaced: true,
  state: noteTopicState,
  getters: noteTopicGetters,
  mutations: noteTopicMutations,
  actions: noteTopicActions
}
