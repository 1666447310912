<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="item in results"
        :key="item._id"
        class="flex-column align-items-start note-list-item"
      >
        <note-item
          :title="item.title"
          :content="item.content"
          :isFavorite="item.isFavorite"
          :createdAt="item.createdAt"
          :editable="false"
        >
          <p class="mb-1" v-html="toHighlightedHTML(item.content)" />
        </note-item>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import NoteItem from "./../note/NoteItem.vue";
export default {
  components: { NoteItem },
  props: {
    results: {
      type: Array
    },
    searchStr: {
      type: String
    }
  },
  methods: {
    toHighlightedHTML(sValue) {
      // all unique insensitive search matches
      const aUniqueMatches = Array.from(
        new Set(
          [...sValue.matchAll(new RegExp(this.searchStr, "gi"))].map(
            elem => elem[0]
          )
        )
      );
      let sModifiedValue = sValue;
      for (let sMatch of aUniqueMatches) {
        if (sMatch) {
          sModifiedValue = sModifiedValue.replace(
            new RegExp(sMatch, "g"),
            `<span class="searchStrMatch">${sMatch}</span>`
          );
        }
      }
      return sModifiedValue;
    }
  }
};
</script>

<style scoped>
.d-flex {
  flex-wrap: wrap;
  word-break: break-all;
}
.mb-1 >>> .searchStrMatch {
  font-weight: 700;
}
</style>
