<template>
  <div class="con">
    <div class="d-flex w-100 justify-content-between align-items-center">
      <b-button
        @click="setShowFavorites()"
        size="sm"
        class="material_btn"
        v-b-tooltip.hover.right
        :title="showOnlyFavorites ? 'show all notes' : 'show only favorites'"
      >
        <i class="material-icons">{{showOnlyFavorites ? 'star' : 'star_border'}}</i>
      </b-button>
      <b-button-group>
        <b-button size="sm" @click="sort()" v-if="notes && notes.length > 0" class="material_btn">
          <i
            class="material-icons"
            :style="{transform: `rotate(-${sortDescending ? '0' : '180'}deg)`}"
          >sort</i>
          <i class="material-icons sort_opt_icon">access_time</i>
        </b-button>
        <b-button @click="showAddNoteModal = true" size="sm" class="material_btn ml-2">
          <i class="material-icons">add</i>
        </b-button>
      </b-button-group>
    </div>
    <note-content-processing-modal
      v-if="showAddNoteModal"
      :topicId="topicId"
      :editMode="false"
      v-model="showAddNoteModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NoteContentProcessingModal from "./NoteContentProcessingModal.vue";

export default {
  components: { NoteContentProcessingModal },
  props: {
    topicId: {
      type: String
    }
  },
  data() {
    return {
      showAddNoteModal: false
    };
  },
  computed: {
    ...mapGetters("notetopics", ["getSortDescending", "getShowOnlyFavorites"]),
    ...mapGetters("notes", ["notes"]),
    sortDescending() {
      return this.getSortDescending(this.topicId);
    },
    showOnlyFavorites() {
      return this.getShowOnlyFavorites(this.topicId);
    }
  },
  methods: {
    ...mapActions("notetopics", ["setSortDescending", "setShowOnlyFavorites"]),
    sort() {
      this.setSortDescending({
        topicId: this.topicId,
        descending: !this.getSortDescending(this.topicId)
      });
    },
    setShowFavorites() {
      this.setShowOnlyFavorites({
        topicId: this.topicId,
        showOnlyFavorites: !this.getShowOnlyFavorites(this.topicId)
      });
    }
  }
};
</script>

<style scoped>
.con {
  padding-bottom: 10px;
  padding-top: 10px;
}
.sort_opt_icon {
  font-size: 14px;
  vertical-align: top;
}
</style>
