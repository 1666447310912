<template>
  <div>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      v-model="showModalLocal"
      :title="`${editMode ? 'Edit' : 'Add'} Note`"
      @ok="editMode ? onEditNote() : onAddNote()"
      size="xl"
    >
      <b-form>
        <b-form-group label="Title:" label-for="titleInput">
          <b-form-input
            id="titleInput"
            type="text"
            v-model="form.title"
            required
            placeholder="Enter title"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Content:" label-for="contentTextarea">
          <b-form-textarea
            id="contentTextarea"
            v-model="form.content"
            placeholder="Enter content"
            required
            no-resize
            rows="5"
            max-rows="20"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
      <div slot="modal-ok">{{editMode ? 'OK' : 'Add'}}</div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    editMode: {
      type: Boolean
    },
    topicId: {
      type: String
    },
    noteId: {
      type: String
    },
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        title: "",
        content: ""
      }
    };
  },
  model: {
    prop: "showModal",
    event: "showModalChange"
  },
  computed: {
    showModalLocal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("showModalChange", value);
      }
    },
    ...mapGetters("notes", ["noteById"]),
    note() {
      return this.editNote
        ? this.noteById(this.noteId)
        : { title: "", content: "" };
    }
  },
  watch: {
    note(val) {
      if (val) {
        this.form.title = val.title;
        this.form.content = val.content;
      }
    }
  },
  methods: {
    ...mapActions("notes", ["editNote", "addNote"]),
    onEditNote(evt) {
      this.editNote({
        _id: this.noteId,
        title: this.form.title,
        content: this.form.content,
        topicId: this.topicId
      });
    },
    onAddNote(evt) {
      this.addNote({
        topicId: this.topicId,
        title: this.form.title,
        content: this.form.content
      });
    }
  }
};
</script>

<style>
</style>
