export default {
  loginSuccess(state, oUser) {
    state.user = oUser;
    state.status = {
      loggedIn: true
    };
  },
  loginFailure(state) {
    state.user = null;
    state.status = {};
  },
  setUserAssigned(state, bIsUserAssigned) {
    state.register.isUserNameAssigned = bIsUserAssigned;
  }
};
