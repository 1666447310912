<template>
  <div class="inherit-height">
    <b-card no-body class="inherit-height">
      <b-tabs
        class="gen-flex-box inherit-height"
        card
        pills
        @input="onTabInputChanged"
        @changed="onTabChanged"
        :value="iSelectedTabIndex"
      >
        <!-- Render Tabs -->
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          class="p-2"
          :title-link-class="{ update_trigger_hack: tab.size.total }"
        >
          <template slot="title">
            {{tab.searchStr ? `Search results of ${tab.searchStr}` : tab.name}}
            <note-size-display :total="tab.size.total" :favorites="tab.size.onlyFavorites" />
            <b-button-close
              @click="closeTopicTab(tab)"
              style="margin-left: 10px;line-height: 0.85 !important;"
            />
          </template>
          <note-tab-container v-cloak v-if="tab.type === getTopicType()" :topicId="tab._id"></note-tab-container>
          <search-results
            v-cloak
            v-if="tab.type === getSearchResultType()"
            :results="tab.results"
            :searchStr="tab.searchStr"
          ></search-results>
        </b-tab>
        <!-- New Tab Button (Using tabs slot) -->
        <template v-slot:tabs-end>
          <b-nav-item slot="tabs" @click.prevent="showAddNewTopicModal" href="#">+</b-nav-item>
        </template>
        <!-- Render this if no tabs -->
        <div slot="empty" class="text-center text-muted">
          There are no open topics
          <br />Create a new topic using + button or manage topics
          <router-link to="/topics" exact>here</router-link>.
        </div>
      </b-tabs>
    </b-card>
    <b-modal
      no-close-on-esc
      v-model="showNewTopicModal"
      title="Name new topic"
      @ok="addNoteTopic({userId: getUserId, name: newTopic.name})"
    >
      <b-form>
        <b-form-group id="topicTabInputGroup" label-for="topicTabNameInput">
          <b-form-input
            id="topicTabNameInput"
            type="text"
            v-model="newTopic.name"
            required
            placeholder="Enter new topic name"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NoteTabContainer from "./NoteTabContainer.vue";
import SearchResults from "./search/SearchResults.vue";
import NoteSizeDisplay from "./util/NoteSizeDisplay.vue";

export default {
  data() {
    return {
      showNewTopicModal: false,
      newTopic: {
        name: ""
      },
      iSelectedTabIndex: null
    };
  },
  components: {
    NoteTabContainer,
    SearchResults,
    NoteSizeDisplay
  },
  beforeMount() {
    this.iSelectedTabIndex = null;
    this.$store.dispatch("notetopics/loadNoteTopics", {
      userId: this.getUserId,
      isOpen: true
    });
  },
  computed: {
    ...mapGetters("notetopics", [
      "noteTopics",
      "getCurrentPage",
      "getNoteLimitSize"
    ]),
    ...mapGetters("searchnotes", ["searchTabs"]),
    ...mapGetters("user", ["getUserId"]),
    tabs: function() {
      return [...this.noteTopics, ...this.searchTabs];
    }
  },
  methods: {
    ...mapGetters("searchnotes", { getSearchResultType: "getTabType" }),
    ...mapGetters("notetopics", { getTopicType: "getTabType" }),
    ...mapActions("notes", ["loadNotes"]),
    ...mapActions("notetopics", ["addNoteTopic", "setNoteTopicIsOpen"]),
    ...mapMutations("searchnotes", ["removeSearchTab"]),
    showAddNewTopicModal() {
      this.showNewTopicModal = true;
      this.newTopic.name = "";
    },
    onTabInputChanged(tab_index) {
      if (tab_index !== null && tab_index >= 0) {
        const oTopic =
          this.noteTopics.length > tab_index
            ? this.noteTopics[tab_index]
            : null;
        if (oTopic && oTopic.type === "T") {
          this.loadNotes(oTopic._id);
        }
      }
    },
    onTabChanged(aCurrentTabs, aPreviousTabs) {
      this.iSelectedTabIndex = aCurrentTabs ? aCurrentTabs.length - 1 : null;
      // load the notes of the new selected tab
      this.onTabInputChanged(this.iSelectedTabIndex);
    },
    closeTopicTab(oTab) {
      if (oTab.type === this.getSearchResultType()) {
        this.removeSearchTab(oTab);
      } else {
        this.setNoteTopicIsOpen({
          topicId: oTab._id,
          userId: this.getUserId,
          isOpen: false
        });
      }
    }
  }
};
</script>

<style scoped>
[v-cloak] {
  display: none !important;
}
</style>

