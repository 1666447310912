import gql from 'graphql-tag';
import graphqlClient from '../../../utils/graphql';
import baseErrorHandler from '../../../utils/baseErrorHandler';

export default {
  loadNoteTopics({
    commit
  }, payload) {
    graphqlClient.query({
        query: gql `query loadNotetopics($userId: ID!, $isOpen: Boolean!) {
            loadNotetopics(userId: $userId, isOpen: $isOpen) {
              _id
              name
              createdAt
              _authorId
              size {
                total
                onlyFavorites
              }
            }
        }`,
        variables: {
          "userId": payload.userId,
          "isOpen": payload.isOpen
        }
      })
      .then(({
        data
      }) => {
        commit('setNoteTopics', data.loadNotetopics === null ? [] : data.loadNotetopics);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  addNoteTopic({
    commit
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation addNotetopic($userId: ID!, $name: String!) {
        addNotetopic(userId: $userId, name: $name) {
              _id
              name
              createdAt
            }
        }`,
        variables: {
          "userId": payload.userId,
          "name": payload.name
        }
      })
      .then(({
        data
      }) => {
        commit("addNoteTopic", data.addNotetopic);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  setNoteTopicIsOpen({
    commit
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation setNotetopicOpen($userId: ID!, $topicId: ID!, $isOpen: Boolean!) {
          setNotetopicOpen(userId: $userId, topicId: $topicId, isOpen: $isOpen) {
              _id
            }
        }`,
        variables: {
          "userId": payload.userId,
          "topicId": payload.topicId,
          "isOpen": payload.isOpen
        }
      })
      .then(({
        data
      }) => {
        commit("removeNoteTopic", payload.topicId);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  loadSizeOfNotes: {
    root: true,
    handler({
      commit
    }, payload) {
      graphqlClient.query({
          query: gql `query loadSizeOfNotes($topicId: ID!) {
        loadSizeOfNotes(topicId: $topicId) {
          total
          onlyFavorites
        }
      }`,
          variables: {
            "topicId": payload
          }
        })
        .then(({
          data
        }) => {
          const oResult = {
            size: data.loadSizeOfNotes ? data.loadSizeOfNotes : {
              total: 0,
              onlyFavorites: 0
            },
            topicId: payload
          }
          commit('setNotesSize', oResult);
        })
        .catch(baseErrorHandler.bind(this, commit));
    }
  },

  setCurrentPage({
    commit,
    dispatch
  }, payload) {
    commit("setCurrentPage", payload);
    dispatch("notes/loadNotes", payload.topicId, {
      root: true
    });
  },

  setNoteLimitSize({
    commit,
    dispatch
  }, payload) {
    commit("setNoteLimitSize", payload);
    dispatch("notes/loadNotes", payload.topicId, {
      root: true
    });
  },

  setSortDescending({
    commit,
    dispatch
  }, payload) {
    commit("setSortDescending", payload);
    dispatch("notes/loadNotes", payload.topicId, {
      root: true
    });
  },

  setShowOnlyFavorites({
    commit,
    dispatch
  }, payload) {
    commit("setShowOnlyFavorites", payload);
    dispatch("notes/loadNotes", payload.topicId, {
      root: true
    });
  }
};
