import Vue from 'vue'

export default {
  isLoggedIn(state) {
    return !!state.user && state.status && state.status.loggedIn;
  },

  getUserId(state) {
    return state.user ? state.user._id : null;
  },

  getUserName(state) {
    return state.user ? state.user.username : "";
  },

  isUserNameAssigned(state) {
    return state.register.isUserNameAssigned;
  }
};
