<template>
  <div class="main-container">
    <b-list-group v-if="!isLoading && noteTopics.length > 0">
      <b-list-group-item
        v-for="topic of noteTopics"
        :key="topic._id"
        class="d-flex justify-content-between align-items-center"
      >
        <b-form-checkbox
          :checked="topic.isOpen"
          @input="onTopicIsOpenChange(topic._id, !topic.isOpen)"
          switch
        >
          {{topic.name}}
          <note-size-display :total="topic.size.total" :favorites="topic.size.onlyFavorites" />
        </b-form-checkbox>
        <div class="ml-2">
          <b-button @click="onEditTopicBtnPressed(topic._id)" size="sm" class="material_btn">
            <i class="material-icons">edit</i>
          </b-button>
          <b-button size="sm" class="material_btn" @click="onRemoveTopicBtnPressed(topic._id)">
            <i class="material-icons">delete</i>
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else-if="!isLoading" class="gen-flex-box pt-2">
      <p class="text-center text-muted">
        There are no topics
        <br />Create a new topic
        <router-link to="/" exact>here</router-link>.
      </p>
    </div>
    <b-modal
      no-close-on-esc
      v-model="showDeleteTopicConfirmationModal"
      title="Warning"
      @ok="removeTopic(currentTopicId)"
    >
      <p>
        Do you really want to delete the topic "{{currentTopic ? currentTopic.name : ""}}"
        <span
          v-if="currentTopic ? currentTopic.size > 0 : false"
        >and the associated {{currentTopic ? currentTopic.size : 0}} notes</span>?
      </p>
    </b-modal>
    <edit-note-topic-modal :topicId="currentTopicId" v-model="showEditTopicModal" />
  </div>
</template>

<script>
import EditNoteTopicModal from "./EditNoteTopicModal.vue";
import NoteSizeDisplay from "./../util/NoteSizeDisplay.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    EditNoteTopicModal,
    NoteSizeDisplay
  },
  data() {
    return {
      showDeleteTopicConfirmationModal: false,
      currentTopicId: null,
      showEditTopicModal: false
    };
  },
  computed: {
    ...mapGetters("notetopicManager", ["noteTopics", "noteTopic", "isLoading"]),
    ...mapGetters("user", ["getUserId"]),
    currentTopic() {
      return this.noteTopic(this.currentTopicId);
    }
  },
  methods: {
    ...mapActions("notetopicManager", ["removeTopic", "setNoteTopicIsOpen"]),
    ...mapMutations("notetopicManager", ["clearAll"]),
    onRemoveTopicBtnPressed(sTopicId) {
      this.currentTopicId = sTopicId;
      this.showDeleteTopicConfirmationModal = true;
    },
    onEditTopicBtnPressed(sTopicId) {
      this.currentTopicId = sTopicId;
      this.showEditTopicModal = true;
    },
    onTopicIsOpenChange(sTopicId, bIsopen) {
      this.setNoteTopicIsOpen({
        topicId: sTopicId,
        userId: this.getUserId,
        isOpen: bIsopen
      });
    }
  },
  beforeMount() {
    this.$store.dispatch("notetopicManager/loadNoteTopics", this.getUserId);
  },
  destroyed() {
    this.clearAll();
  }
};
</script>

<style scoped>
.main-container {
  overflow-y: auto;
}
</style>

