<template>
  <div class="gen-flex-box main-container">
    <app-nav-bar class="nav-container" />
    <router-view></router-view>
    <base-error-alert />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AppNavBar from "./components/AppNavBar.vue";
import NotesContainer from "./components/NotesContainer.vue";
import BaseErrorAlert from "./components/error/BaseErrorAlert.vue";

export default {
  name: "App",
  components: {
    AppNavBar,
    NotesContainer,
    BaseErrorAlert,
  },
  methods: {
    ...mapMutations("user", ["loginSuccess"]),
  },
  beforeMount() {
    let oUser = null;
    try {
      oUser = JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      // do nothing
    }
    if (oUser && oUser._id && oUser.token) {
      this.loginSuccess(oUser);
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.main-container {
  height: 100vh;
}

.nav-container {
  flex-shrink: 0;
}

.material_btn {
  padding: 0.25rem 0.25rem 0rem 0.25rem !important;
}

.gen-flex-box {
  display: flex;
  flex-direction: column;
}

.tabs > .tab-content {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: hidden;
}

.tab-content > .card-body {
  height: inherit;
  min-height: 1px;
}

.inherit-height {
  height: inherit;
}

.nodesSizeText {
  margin-right: 7px;
  font-size: 0.7rem;
  vertical-align: text-top;
}

.note-list-item {
  background-color: lightgray !important;
  margin-top: 5px;
  white-space: pre-line;
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") !important;
}
</style>
