<template>
  <div class="gen-flex-box inherit-height" style="overflow-y:auto;">
    <b-list-group v-if="!isLoading && notes.length > 0" class="gen-flex-box inherit-height">
      <b-list-group-item
        v-for="note in notes"
        :key="note._id"
        class="flex-column align-items-start note-list-item"
      >
        <note-item
          :title="note.title"
          :content="note.content"
          :isFavorite="note.isFavorite"
          :createdAt="note.createdAt"
          :editable="true"
          @setIsNoteFavorite="changeFavorite(note._id, !note.isFavorite)"
        />
        <div class="d-flex flex-row justify-content-end align-items-center">
          <div class="mt-1 align-self-end">
            <b-button @click="onEditNoteClicked(note._id)" size="sm" class="material_btn">
              <i class="material-icons">edit</i>
            </b-button>
            <b-button @click="onDeleteNodeClicked(note._id)" size="sm" class="material_btn">
              <i class="material-icons">delete</i>
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else-if="!isLoading" class="gen-flex-box inherit-height">
      <p class="text-center text-muted">
        There are no notes
        <br />Create a new note using + button.
      </p>
    </div>
    <b-modal
      no-close-on-esc
      v-model="showDeleteNodeConfirmationModal"
      title="Warning"
      @ok="removeNote({noteId: currentId, topicId: topicId})"
    >
      <p>Do you really want to delete the note?</p>
    </b-modal>
    <note-content-processing-modal
      :noteId="currentId"
      :editMode="true"
      v-model="showEditNoteModal"
      :topicId="topicId"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NoteContentProcessingModal from "./NoteContentProcessingModal.vue";
import NoteItem from "./note/NoteItem.vue";

export default {
  components: { NoteContentProcessingModal, NoteItem },
  props: {
    topicId: {
      type: String
    }
  },
  data() {
    return {
      showDeleteNodeConfirmationModal: false,
      showEditNoteModal: false,
      currentId: null
    };
  },
  computed: {
    ...mapGetters("notes", ["notes", "isLoading"])
  },
  methods: {
    ...mapActions("notes", ["removeNote", "setIsNoteFavorite"]),
    onDeleteNodeClicked(sNoteId) {
      this.showDeleteNodeConfirmationModal = true;
      this.currentId = sNoteId;
    },
    onEditNoteClicked(sNoteId) {
      this.showEditNoteModal = true;
      this.currentId = sNoteId;
    },
    changeFavorite(sNoteId, bIsFavorite) {
      this.setIsNoteFavorite({
        topicId: this.topicId,
        _id: sNoteId,
        isFavorite: bIsFavorite
      });
    }
  }
};
</script>

<style scoped>
.list-group {
  overflow-y: auto;
}
.list-item-right-group {
  flex-shrink: 0;
  margin-left: 5px;
}
</style>
