function _getNote(state, sNoteId) {
  return state.notes.find(elem => elem._id === sNoteId);
};

export default {
  setNotes(state, payload) {
    state.notes = payload;
  },

  addNote(state, payload) {
    state.notes.push(payload);
  },

  removeNote(state, payload) {
    const oRemovedNote = _getNote(state, payload);
    if (oRemovedNote) {
      state.notes.splice(state.notes.indexOf(oRemovedNote), 1)
    }
  },

  clearNotes(state) {
    state.notes = [];
  },

  editNote(state, payload) {
    const oEditedNote = _getNote(state, payload._id);
    if (oEditedNote) {
      oEditedNote.title = payload.title;
      oEditedNote.content = payload.content;
    }
  },

  setLoading(state, payload) {
    state.isLoading = payload;
  },

  setIsNoteFavorite(state, payload) {
    const oEditedNote = _getNote(state, payload._id);
    if (oEditedNote) {
      oEditedNote.isFavorite = payload.isFavorite;
    }
  }
};
