export default {
  setError(state, oError) {
    state.error = oError;
  },
  clearError(state) {
    state.error = null;
  },
  setLoginError(state, oError) {
    state.loginError = oError;
  },
  clearLoginError(state) {
    state.loginError = null;
  }
};
