import gql from 'graphql-tag';
import graphqlClient from '../../../utils/graphql';
import baseErrorHandler from '../../../utils/baseErrorHandler';

export default {
  loadNotes({
    commit,
    rootGetters
  }, sTopicId) {
    commit('setLoading', true);
    const iLimitSize = rootGetters['notetopics/getNoteLimitSize'](sTopicId);
    const iCurrentPage = rootGetters['notetopics/getCurrentPage'](sTopicId);
    graphqlClient.query({
        query: gql `query loadNotes($topicId: ID!, $limitSize: Int!, $skipSize: Int!, $sortDescending: Boolean!, $showOnlyFavorites: Boolean) {
            loadNotes(topicId: $topicId, limitSize: $limitSize, skipSize: $skipSize, sortDescending: $sortDescending, showOnlyFavorites: $showOnlyFavorites) {
              _id
              title
              content
              isFavorite
              createdAt
            }
        }`,
        variables: {
          topicId: sTopicId,
          limitSize: iLimitSize,
          skipSize: (iCurrentPage - 1) * iLimitSize,
          sortDescending: rootGetters['notetopics/getSortDescending'](sTopicId),
          showOnlyFavorites: rootGetters['notetopics/getShowOnlyFavorites'](sTopicId)
        }
      })
      .then(({
        data
      }) => {
        commit('setNotes', data.loadNotes === null ? [] : data.loadNotes);
        commit('setLoading', false);
      })
      .catch((error) => {
        baseErrorHandler(commit, error);
        commit('setLoading', false);
      });
  },

  addNote({
    commit,
    dispatch
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation addNote($topicId: ID!, $title: String!, $content: String!) {
        addNote(topicId: $topicId, title: $title, content: $content) {
              _id
              title
              content
              createdAt
            }
        }`,
        variables: {
          "topicId": payload.topicId,
          "title": payload.title,
          "content": payload.content
        }
      })
      .then(({
        data
      }) => {
        // global registered namespaced action
        dispatch("loadSizeOfNotes", payload.topicId, {
          root: true
        });
        dispatch("loadNotes", payload.topicId);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  removeNote({
    commit,
    dispatch
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation removeNote($noteId: ID!) {
        removeNote(noteId: $noteId)
        }`,
        variables: {
          "noteId": payload.noteId
        }
      })
      .then(({
        data
      }) => {
        // global registered namespaced action
        dispatch("loadSizeOfNotes", payload.topicId, {
          root: true
        });
        dispatch("loadNotes", payload.topicId);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  editNote({
    commit,
    dispatch,
    rootGetters
  }, {
    _id,
    content,
    title,
    topicId,
    isFavorite
  }) {
    graphqlClient.mutate({
        mutation: gql `mutation editNote($note: NoteInput!) {
          editNote(note: $note) {
              _id
              title
              content
              isFavorite
            }
        }`,
        variables: {
          "note": {
            _id,
            content,
            title,
            isFavorite
          }
        }
      })
      .then(({
        data
      }) => {
        commit("editNote", data.editNote);
        if (!isFavorite && rootGetters["notetopics/getShowOnlyFavorites"](topicId)) {
          // load all notes when the note isn't a favorite and the favorite filter is active
          dispatch("loadNotes", topicId);
        }
        dispatch("loadSizeOfNotes", topicId, {
          root: true
        });
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  setIsNoteFavorite({
    commit,
    dispatch,
  }, payload) {
    dispatch("editNote", payload);
    commit("setIsNoteFavorite", payload);
  }
};
