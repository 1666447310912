export default {
  searchTabs(state) {
    return state.searchTabs;
  },
  isLoading(state) {
    return state.isLoading;
  },
  getTabType(state) {
    return state.TAB_TYPE;
  }
};
