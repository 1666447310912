const DEFAULT_ERROR_MESSAGE = "Something went wrong";

const fnGetErrorMessage = oError => {
    if(!oError) {
        return DEFAULT_ERROR_MESSAGE;
    }
    if(oError && oError.graphQLErrors.length > 0) {
        return oError.graphQLErrors[0].message || DEFAULT_ERROR_MESSAGE;
    }
    return oError.message || DEFAULT_ERROR_MESSAGE;
}

export default (fnCommit, oError) => {
    fnCommit('error/setError', {
    message: fnGetErrorMessage(oError)
  }, {
    root: true
  });
};