import gql from 'graphql-tag';
import graphqlClient from '../../../utils/graphql';
import baseErrorHandler from '../../../utils/baseErrorHandler';

export default {
  loadNoteTopics({
    commit
  }, payload) {
    commit('setLoading', true);
    graphqlClient.query({
        query: gql `query loadAllNotetopics($userId: ID!) {
          loadAllNotetopics(userId: $userId) {
              _id
              name
              createdAt
              _authorId
              size {
                total
                onlyFavorites
              }
              isOpen
            }
        }`,
        variables: {
          "userId": payload
        }
      })
      .then(({
        data
      }) => {
        commit('setNoteTopics', data.loadAllNotetopics === null ? [] : data.loadAllNotetopics);
        commit('setLoading', false);
      })
      .catch((error) => {
        baseErrorHandler(commit, error);
        commit('setLoading', false);
      });
  },

  removeTopic({
    commit,
    dispatch
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation removeTopic($topicId: ID!) {
        removeTopic(topicId: $topicId)
        }`,
        variables: {
          "topicId": payload
        }
      })
      .then(({
        data
      }) => {
        commit("removeTopic", payload);
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  setNoteTopicIsOpen({
    commit
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation setNotetopicOpen($userId: ID!, $topicId: ID!, $isOpen: Boolean!) {
          setNotetopicOpen(userId: $userId, topicId: $topicId, isOpen: $isOpen) {
              _id
            }
        }`,
        variables: {
          "userId": payload.userId,
          "topicId": payload.topicId,
          "isOpen": payload.isOpen
        }
      })
      .then(({
        data
      }) => {
        commit("setNoteTopicIsOpen", {
          topicId: payload.topicId,
          isOpen: payload.isOpen
        });
      })
      .catch(baseErrorHandler.bind(this, commit));
  },

  setTopicName({
    commit
  }, payload) {
    graphqlClient.mutate({
        mutation: gql `mutation setTopicName($topicId: ID!, $name: String!) {
          setTopicName(topicId: $topicId, name: $name) {
              _id
            }
        }`,
        variables: {
          "topicId": payload.topicId,
          "name": payload.name
        }
      })
      .then(({
        data
      }) => {
        commit("setTopicName", payload);
      })
      .catch(baseErrorHandler.bind(this, commit));
  }
};
