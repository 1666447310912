import Vue from 'vue'

export default {
  error(state) {
    return state.error;
  },
  loginError(state) {
    return state.loginError;
  }
};
