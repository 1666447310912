import gql from 'graphql-tag';
import graphqlClient from '../../../utils/graphql';
import router from '../../../router/index';
import baseErrorHandler from '../../../utils/baseErrorHandler';
import loginErrorHandler from '../../../utils/loginErrorHandler';

export default {
  authenticate({
    commit
  }, payload) {
    commit('error/clearLoginError', null, {
      root: true
    });
    // commit('setLoading', true);
    graphqlClient.query({
      query: gql`query authenticate($username: String!, $password: String!) {
            authenticate(username: $username, password: $password) {
              _id
              token
              username
            }
        }`,
      variables: {
        "username": payload.username,
        "password": payload.password
      }
    })
      .then(({
        data,
        errors
      }) => {
        // login successful if there's a jwt token in the response
        if (data.authenticate && data.authenticate.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(data.authenticate));
          commit('loginSuccess', data.authenticate);
          router.push("/");
          return;
        }
        loginErrorHandler(commit, errors);
        // commit('setLoading', false);
      })
      .catch((error) => {
        baseErrorHandler(commit, error);
        commit('loginFailure', error);
        // commit('setLoading', false);
      });
  },
  logOut({
    commit
  }, payload) {
    localStorage.removeItem('user');
    commit('loginFailure');
    // clear all
    commit('searchnotes/clearSearchResults', null, {
      root: true
    });
    commit('notetopics/clearNoteTopics', null, {
      root: true
    });
    commit('notes/clearNotes', null, {
      root: true
    });
  },
  register({
    commit
  }, payload) {
    // commit('setLoading', true);
    graphqlClient.mutate({
      mutation: gql`mutation register($username: String!, $password: String!, $email: String!) {
            register(username: $username, password: $password, email: $email) {
              _id
              token
              username
            }
        }`,
      variables: {
        "username": payload.username,
        "password": payload.password,
        "email": payload.email
      }
    })
      .then(({
        data
      }) => {
        // login successful if there's a jwt token in the response
        if (data.register && data.register.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', JSON.stringify(data.register));
        }
        commit('loginSuccess', data.register);
        router.push("/");
        // commit('setLoading', false);
      })
      .catch((error) => {
        baseErrorHandler(commit, error);
        commit('loginFailure', error);
        // commit('setLoading', false);
      });
  },
  isUserAssigned({
    commit
  }, payload) {
    graphqlClient.query({
      query: gql`query isUserAssigned($username: String!) {
            isUserAssigned(username: $username)
        }`,
      variables: {
        "username": payload,
      }
    })
      .then(({
        data
      }) => {
        commit('setUserAssigned', data.isUserAssigned);
      })
      .catch(baseErrorHandler.bind(this, commit));
  }
};
