<template>
  <div class="sizeContainer">
    <span v-if="total > 0" class="size">{{total}}</span>
    <div v-if="favorites && favorites > 0" class="favoriteSize">
      <i class="material-icons star">star</i>
      <span class="size">{{favorites}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    favorites: Number
  }
};
</script>

<style>
.sizeContainer {
  vertical-align: top;
  display: inline-block;
  font-size: 0.7rem;
}
.size {
  vertical-align: inherit;
}
.favoriteSize {
  display: inherit;
  vertical-align: inherit;
  margin-left: -1px;
}
.star {
  display: inherit;
  font-size: medium !important;
}
</style>