import Vue from 'vue'
import Router from 'vue-router'
import NotesContainer from './../components/NotesContainer.vue'
import TopicManagerContainer from './../components/topics/TopicManagerContainer.vue'
import LoginPage from './../components/LoginPage.vue';
import RegisterPage from './../components/RegisterPage.vue';

Vue.use(Router)

const oRouter = new Router({
  routes: [{
    path: "/",
    component: NotesContainer
  }, {
    path: "/topics",
    component: TopicManagerContainer
  }, {
    path: '/login',
    component: LoginPage
  }, {
    path: '/register',
    component: RegisterPage
  }],
  mode: "history"
});

oRouter.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

export default oRouter;
