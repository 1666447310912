import gql from 'graphql-tag';
import graphqlClient from '../../../utils/graphql';
import baseErrorHandler from '../../../utils/baseErrorHandler';

export default {
  searchNotes({
    commit
  }, payload) {
    commit('setLoading', true);
    graphqlClient.query({
        query: gql `query searchNotes($userId: ID!, $searchStr: String!) {
          searchNotes(userId: $userId, searchStr: $searchStr) {
              _id
              name
              notes {
                _id
                title
                content
                createdAt
                isFavorite
              }
              size {
                total
                onlyFavorites
              }
            }
        }`,
        variables: {
          "userId": payload.userId,
          "searchStr": payload.searchStr
        }
      })
      .then(({
        data
      }) => {
        const oDefaultSize = {
          total: 0,
          onlyFavorites: 0
        };
        commit('addSearchTab', {
          searchStr: payload.searchStr,
          results: data.searchNotes,
          size: data.searchNotes ?
            data.searchNotes.reduce((oSizeTotal, elem) => ({
              total: oSizeTotal.total + elem.size.total,
              onlyFavorites: oSizeTotal.onlyFavorites + elem.size.onlyFavorites
            }), oDefaultSize) : oDefaultSize
        });
        commit('setLoading', false);
      })
      .catch((error) => {
        baseErrorHandler(commit, error);
        commit('setLoading', false);
      });
  }
};
