import searchNotesState from "./searchNotesState";
import searchNotesGetters from "./searchNotesGetters";
import searchNotesMutations from "./searchNotesMutations";
import searchNotesActions from "./searchNotesActions";

export default {
  namespaced: true,
  state: searchNotesState,
  getters: searchNotesGetters,
  mutations: searchNotesMutations,
  actions: searchNotesActions
}
