export default {
  noteTopics(state) {
    return state.noteTopics;
  },
  getTabType(state) {
    return state.TAB_TYPE;
  },
  getNoteSize: state => sTopicId => {
    const oTopic = state.noteTopics.find(elem => elem._id === sTopicId);
    return oTopic ? oTopic.size : {
      total: 0,
      onlyFavorites: 0
    };
  },
  getCurrentPage: state => sTopicId => {
    const oTopic = state.noteTopics.find(elem => elem._id === sTopicId);
    return oTopic ? oTopic.currentPage : 1;
  },
  getNoteLimitSize: state => sTopicId => {
    const oTopic = state.noteTopics.find(elem => elem._id === sTopicId);
    return oTopic ? oTopic.noteLimitSize : 1;
  },
  getSortDescending: state => sTopicId => {
    const oTopic = state.noteTopics.find(elem => elem._id === sTopicId);
    return oTopic ? oTopic.sort.descending : false;
  },
  getShowOnlyFavorites: state => sTopicId => {
    const oTopic = state.noteTopics.find(elem => elem._id === sTopicId);
    return oTopic ? oTopic.showOnlyFavorites : state.default.showOnlyFavorites;
  }
};
