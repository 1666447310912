<template>
  <div>
    <b-alert v-model="isLoginFailed" variant="danger" fade>
      {{ loginError ? loginError.message : "" }}
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginErrorAlert",
  computed: {
    ...mapGetters("error", ["loginError"]),
    isLoginFailed() {
      return !!this.loginError;
    },
  },
};
</script>

<style>
</style>