<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand to="/">NoteMaster</b-navbar-brand>
      <b-navbar-toggle target="nav_collapse" />
      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item v-if="isLoggedIn" to="/topics" exact>Topics</b-nav-item>
          <b-nav-item v-if="!isLoggedIn" to="/login" exact>Login</b-nav-item>
          <b-nav-item v-if="!isLoggedIn" to="/register" exact>Register</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
          <b-nav-form>
            <b-form-input
              @keydown.enter.prevent="onSearchStringEntered"
              v-model="searchInput"
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              :disabled="!isSearchInputValid"
              @click="onSearchStringEntered"
            >Search</b-button>
          </b-nav-form>
          <b-nav-item-dropdown right>
            <!-- Using button-content slot -->
            <template slot="button-content">
              <em>{{getUserName}}</em>
            </template>
            <b-dropdown-item disabled href="#">Profile</b-dropdown-item>
            <b-dropdown-item @click.prevent="loggingOut" href="#">Signout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return { searchInput: "", searchStr: "" };
  },
  methods: {
    ...mapActions("user", ["logOut"]),
    ...mapActions("searchnotes", ["searchNotes"]),
    loggingOut() {
      this.logOut();
      this.$router.push("/login");
    },
    onSearchStringEntered() {
      if (this.isSearchInputValid) {
        // copy search input value, because of two way binding of input value
        // we only need the search value after enter the input
        this.searchStr = this.searchInput.toString().trim();
        this.searchNotes({
          userId: this.getUserId,
          searchStr: this.searchStr
        });
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapGetters("user", ["getUserName"]),
    ...mapGetters("user", ["getUserId"]),
    isSearchInputValid() {
      return this.searchInput.trim().length > 0;
    }
  }
};
</script>

<style scoped>
</style>
