import errorState from "./errorState";
import errorGetters from "./errorGetters";
import errorMutations from "./errorMutations";

export default {
  namespaced: true,
  state: errorState,
  getters: errorGetters,
  mutations: errorMutations
}
