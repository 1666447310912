import Vue from 'vue'

export default {
  notes(state) {
    return state.notes;
  },
  noteById: state => payload => {
    return state.notes.find(elem => elem._id === payload);
  },
  isLoading(state) {
    return state.isLoading;
  }
};
