<template>
  <div class="gen-flex-box" style="height: 100%;">
    <div v-if="!isLoading && results.length > 0" role="tablist" class="tablist inherit-height">
      <b-card v-for="note of results" :key="note._id" no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-btn block href="#" v-b-toggle="'accordion' + note._id" variant="info">
            {{note.name}}
            <note-size-display :total="note.size.total" :favorites="note.size.onlyFavorites" />
          </b-btn>
        </b-card-header>
        <b-collapse :id="'accordion' + note._id" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <note-result-item-list :results="note.notes" :searchStr="searchStr"></note-result-item-list>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <div v-else-if="!isLoading" class="gen-flex-box inherit-height">
      <p class="text-center text-muted">
        There are no results
        <br />Show results using search button.
      </p>
    </div>
  </div>
</template>

<script>
import NoteResultItemList from "./NoteResultItemList.vue";
import NoteSizeDisplay from "./../util/NoteSizeDisplay.vue";

export default {
  components: {
    NoteResultItemList,
    NoteSizeDisplay
  },
  props: {
    searchStr: {
      type: String
    },
    results: {
      type: Array
    }
  }
};
</script>

<style scoped>
.tablist {
  overflow-y: auto;
}
</style>
