const DEFAULT_ERROR_MESSAGE = "Something went wrong";

const fnGetErrorMessage = aErrors => {
  if (!aErrors) {
    return DEFAULT_ERROR_MESSAGE;
  }
  if (aErrors.length > 0) {
    return aErrors[0].message || DEFAULT_ERROR_MESSAGE;
  }
  return DEFAULT_ERROR_MESSAGE;
}

export default (fnCommit, aErrors) => {
  fnCommit('error/setLoginError', {
    message: fnGetErrorMessage(aErrors)
  }, {
    root: true
  });
};