<template>
  <div class="gen-flex-box" style="height:100%;">
    <notes-header :topicId="topicId"></notes-header>
    <note-list :topicId="topicId"></note-list>
    <div class="row paginationContainer align-content flex-nowrap pt-2 justify-content-between">
      <b-pagination
        v-if="size > 0"
        :disabled="size <= limitSize"
        v-model="currentPage"
        :total-rows="size"
        :per-page="limitSize"
        size="sm"
        style="margin-bottom:0rem;"
      />
      <b-button-group v-if="size >= 30" class="ml-3">
        <b-button
          size="sm"
          @click="setLimitSize(15)"
          :variant="limitSize === 15 ? 'primary' : 'outline-primary'"
          v-b-tooltip.hover
          title="show 15 notes per page"
        >15</b-button>
        <b-button
          size="sm"
          @click="setLimitSize(30)"
          class="ml-2"
          :variant="limitSize === 30 ? 'primary' : 'outline-primary'"
          v-b-tooltip.hover
          title="show 30 notes per page"
        >30</b-button>
        <b-button
          v-if="size >= 50"
          @click="setLimitSize(50)"
          size="sm"
          class="ml-2"
          :variant="limitSize === 50 ? 'primary' : 'outline-primary'"
          v-b-tooltip.hover
          title="show 50 notes per page"
        >50</b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import NotesHeader from "./NotesHeader.vue";
import NoteList from "./NoteList.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    NotesHeader,
    NoteList
  },
  props: {
    topicId: {
      type: String
    }
  },
  computed: {
    ...mapGetters("notetopics", [
      "getNoteSize",
      "getCurrentPage",
      "getNoteLimitSize",
      "getShowOnlyFavorites"
    ]),
    size() {
      const oNoteSize = this.getNoteSize(this.topicId);
      return this.getShowOnlyFavorites(this.topicId)
        ? oNoteSize.onlyFavorites
        : oNoteSize.total;
    },
    limitSize() {
      return this.getNoteLimitSize(this.topicId);
    },
    currentPage: {
      get() {
        return this.getCurrentPage(this.topicId);
      },
      set(iValue) {
        this.setCurrentPage({ topicId: this.topicId, currentPage: iValue });
      }
    }
  },
  methods: {
    ...mapActions("notetopics", ["setCurrentPage", "setNoteLimitSize"]),
    setLimitSize(iValue) {
      if (iValue !== this.limitSize) {
        this.setNoteLimitSize({
          topicId: this.topicId,
          limitSize: iValue
        });
      }
    }
  }
};
</script>

<style>
.paginationContainer {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
</style>

