export default {
  setNoteTopics(state, payload) {
    state.noteTopics = payload;
  },
  addNoteTopic(state, payload) {
    state.noteTopics.push(payload);
  },
  removeTopic(state, payload) {
    const oTopic = state.noteTopics.find(elem => elem._id === payload);
    if (oTopic && state.noteTopics.indexOf(oTopic) > -1) {
      state.noteTopics.splice(state.noteTopics.indexOf(oTopic), 1);
    }
  },
  setNoteTopicIsOpen(state, payload) {
    const oTopic = state.noteTopics.find(elem => elem._id === payload.topicId);
    if (oTopic) {
      oTopic.isOpen = payload.isOpen;
    }
  },
  clearAll(state) {
    state.noteTopics = [];
  },
  setTopicName(state, payload) {
    const oTopic = state.noteTopics.find(elem => elem._id === payload.topicId);
    if (oTopic) {
      oTopic.name = payload.name;
    }
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  }
};
