export default {
  noteTopics(state) {
    return state.noteTopics;
  },
  noteTopic: state => payload => {
    return state.noteTopics.find(elem => elem._id === payload);
  },
  isLoading(state) {
    return state.isLoading;
  }
};
