<template>
  <div>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      v-model="showModalLocal"
      title="Edit topic"
      @ok="setTopicName({topicId, name})"
      size="m"
    >
      <b-form>
        <b-form-group label="Title:" label-for="nameInput">
          <b-form-input id="nameInput" type="text" v-model="name" required placeholder="Enter name"></b-form-input>
        </b-form-group>
      </b-form>
      <div slot="modal-ok">Save</div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    topicId: {
      type: String
    },
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      name: ""
    };
  },
  model: {
    prop: "showModal",
    event: "showModalChange"
  },
  computed: {
    showModalLocal: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("showModalChange", value);
      }
    },
    ...mapGetters("notetopicManager", ["noteTopic"]),
    topic() {
      return this.noteTopic(this.topicId);
    }
  },
  watch: {
    topic(val) {
      if (val) {
        this.name = val.name;
      }
    }
  },
  methods: {
    ...mapActions("notetopicManager", ["setTopicName"])
  }
};
</script>

<style>
</style>
