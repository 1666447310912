<template>
  <div>
    <b-alert
      v-model="errorAlertDuration"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
      fade
      @dismissed="clearError()"
    >
      {{ error ? error.message : "" }}
    </b-alert>
  </div>
</template>

<script>
const ERROR_ALERT_SHOW_DURATION = 4;
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "BaseErrorAlert",
  data() {
    return {};
  },
  methods: {
    ...mapMutations("error", ["clearError"]),
  },
  computed: {
    ...mapGetters("error", ["error"]),
    errorAlertDuration: {
      get() {
        return !!this.error ? ERROR_ALERT_SHOW_DURATION : 0;
      },
      set(iValue) {
        /** do nothing */
      },
    },
  },
};
</script>

<style>
</style>