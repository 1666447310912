function _getNoteTopic(state, sTopicId) {
  return state.noteTopics.find(elem => elem._id === sTopicId);
}

export default {
  setNoteTopics(state, payload) {
    state.noteTopics = payload ? payload.map(elem => {
      const oTopic = state.noteTopics.find(topic => topic._id === elem._id);
      return {
        ...elem,
        type: state.TAB_TYPE,
        currentPage: oTopic ? oTopic.currentPage : state.default.currentPage,
        noteLimitSize: oTopic ? oTopic.noteLimitSize : state.default.limitSize,
        sort: oTopic ? oTopic.sort : {
          descending: false
        },
        showOnlyFavorites: state.default.showOnlyFavorites
      };
    }) : [];
  },

  clearNoteTopics(state) {
    state.noteTopics = [];
  },

  addNoteTopic(state, payload) {
    state.noteTopics.push({
      ...payload,
      size: {
        total: 0,
        onlyFavorites: 0
      },
      type: state.TAB_TYPE,
      currentPage: state.default.currentPage,
      noteLimitSize: state.default.limitSize,
      sort: {
        descending: false
      },
      showOnlyFavorites: state.default.showOnlyFavorites
    });
  },

  removeNoteTopic(state, payload) {
    const oTopic = _getNoteTopic(state, payload);
    if (oTopic && state.noteTopics.indexOf(oTopic) > -1) {
      state.noteTopics.splice(state.noteTopics.indexOf(oTopic), 1);
    }
  },

  setNotesSize(state, payload) {
    const oTopic = _getNoteTopic(state, payload.topicId);
    if (oTopic) {
      oTopic.size = payload.size;
    }
  },

  setCurrentPage(state, payload) {
    const oTopic = _getNoteTopic(state, payload.topicId);
    if (oTopic) {
      oTopic.currentPage = payload.currentPage;
    }
  },

  setNoteLimitSize(state, payload) {
    const oTopic = _getNoteTopic(state, payload.topicId);
    if (oTopic) {
      oTopic.noteLimitSize = payload.limitSize;
      if (oTopic.size <= payload.limitSize) {
        oTopic.currentPage = 1;
      }
    }
  },

  setSortDescending(state, payload) {
    const oTopic = _getNoteTopic(state, payload.topicId);
    if (oTopic) {
      oTopic.sort.descending = payload.descending;
    }
  },

  setShowOnlyFavorites(state, payload) {
    const oTopic = _getNoteTopic(state, payload.topicId);
    if (oTopic) {
      oTopic.showOnlyFavorites = payload.showOnlyFavorites;
    }
  }
};
