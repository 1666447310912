export default {
  addSearchTab(state, payload) {
    state.searchTabs.push({
      ...payload,
      type: state.TAB_TYPE
    });
  },
  removeSearchTab(state, payload) {
    if (payload && state.searchTabs.indexOf(payload) > -1) {
      state.searchTabs.splice(state.searchTabs.indexOf(payload), 1);
    }
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  }
};
