import noteTopicState from "./noteTopicState";
import noteTopicGetters from "./noteTopicGetters";
import noteTopicMutations from "./noteTopicMutations";
import noteTopicActions from "./noteTopicActions";

export default {
  namespaced: true,
  state: noteTopicState,
  getters: noteTopicGetters,
  mutations: noteTopicMutations,
  actions: noteTopicActions
}
