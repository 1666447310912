<template>
  <div class="p-3">
    <b-form>
      <b-form-group label="Username:" label-for="usernameInput">
        <b-form-input
          id="usernameInput"
          type="text"
          v-model="login.user"
          required
          placeholder="Enter username"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Password:" label-for="passwordInput">
        <b-form-input
          id="passwordInput"
          type="password"
          v-model="login.password"
          placeholder="Enter password"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <b-button @click="onLoginBtnPressed()">Login</b-button>
    <login-error-alert class="mt-3" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoginErrorAlert from "./error/LoginErrorAlert.vue";
export default {
  components: { LoginErrorAlert },
  data() {
    return {
      login: {
        user: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("user", ["authenticate"]),
    onLoginBtnPressed() {
      this.authenticate({
        username: this.login.user,
        password: this.login.password,
      });
    },
  },
};
</script>
