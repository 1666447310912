<template>
  <div>
    <div class="d-flex w-100 justify-content-between">
      <div class="d-flex align-items-center">
        <i
          class="material-icons favorite_icon"
          :style="{color: isFavorite ? 'inherit' : 'gray', cursor: editable ? 'pointer' : 'default'}"
          @click="editable ? $emit('setIsNoteFavorite') : null"
        >{{isFavorite ? 'star' : 'star_border'}}</i>
        <h6 class="mb-1 font-weight-bold">{{title}}</h6>
      </div>
      <small class="list-item-right-group">{{createdAt | moment("from", "now")}}</small>
    </div>
    <div class="d-flex flex-column w-100 justify-content-between">
      <slot>
        <p class="mb-1">{{content}}</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    isFavorite: Boolean,
    createdAt: String,
    editable: Boolean
  }
};
</script>

<style>
.favorite_icon {
  margin-right: 2px;
  font-size: x-large !important;
}
</style>