import userState from "./userState";
import userGetters from "./userGetters";
import userMutations from "./userMutations";
import userActions from "./userActions";

export default {
  namespaced: true,
  state: userState,
  getters: userGetters,
  mutations: userMutations,
  actions: userActions
}
