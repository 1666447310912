import noteState from "./noteState";
import noteGetters from "./noteGetters";
import noteMutations from "./noteMutations";
import noteActions from "./noteActions";

export default {
  namespaced: true,
  state: noteState,
  getters: noteGetters,
  mutations: noteMutations,
  actions: noteActions
}
